
import Vue from 'vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import TTDatepickerField from '@/components/ui/TTDatepickerField.vue';
import { tsxassApi } from '@/services/api';
import NavigateToYandexGPT from '@/views/app/Analytics/NavigateToYandexGPT.vue';
import { V1EntitiesDevelopmentTasksQuestionsQuestion } from '@/services/api/tsxass';
import errorHandler from '@/helpers/errorHandler';

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
};

export default Vue.extend({
  name: 'DevelopmentTaskEditForm',

  components: {
    TTLoader,
    TTDatepickerField,
    NavigateToYandexGPT,
  },

  inject: ['RouteNames'],

  props: {
    taskId: {
      type: [String, Number],
      default: undefined,
    },
    userId: {
      type: String,
      default: undefined,
    },
    surveyId: {
      type: Number,
      default: undefined,
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    factor: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: undefined,
    },
    showHint: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      formData: { ...this.getInitialFormValue() },
      questions: [] as V1EntitiesDevelopmentTasksQuestionsQuestion[],
      requiredFields: ['name', 'scheduledAt'],
      snackbarSuccess: false,
      snackbarError: false,
    };
  },

  computed: {
    validationRules() {
      return {
        notEmpty: [(v: string): boolean | string => !!v?.trim() || (this.$t('commonValidation.required') as string)],
        hasValue: [(v: any): boolean | string => v ?? (this.$t('commonValidation.required') as string)],
      };
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    isAllRequiredFieldsFilled(): boolean {
      return Object.keys(this.getInitialFormValue())
        .every((v) => (this.requiredFields.includes(v) ? this.formData[v] : true));
    },
    submitText() {
      return this.isCreating
        ? this.$t('addButton')
        : this.$t('save');
    },
    minDateTime(): string {
      return this.$dayjs().add(1, 'd').format('YYYY-MM-DD');
    },
    developmentTasksRoute() {
      return {
        name: this.RouteNames.R_APP_SURVEY_ANALYTICS,
        params: { surveyId: this.surveyId },
        query: {
          userId: this.userId,
          tab: 'development_tasks',
        },
      };
    },
    cancelButtonText() {
      return this.cancelText || this.$t('cancelButton');
    },
  },

  watch: {
    factor: {
      handler(value) {
        if (value) {
          this.setFactor(value);
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    await this.getIndicators();
    if (!this.isCreating) {
      await this.getTask();
    }
  },

  methods: {
    setFactor(factor) {
      this.formData.questionId = factor?.questionId;
    },
    getInitialFormValue() {
      return {
        name: '',
        questionId: undefined,
        scheduledAt: this.$dayjs().add(1, 'd').format('YYYY-MM-DD'),
      };
    },
    async submitForm() {
      try {
        this.loading = true;
        if (this.isCreating) {
          await tsxassApi.postV1AnalyticsFeedbackDevelopmentTasks({
            ...this.formData,
            surveyId: this.surveyId,
            userId: this.userId,
          });
          this.$emit('submit');
          if (!this.isDialog) {
            this.resetForm();
            this.setFactor(this.factor);
          }
          this.snackbarSuccess = true;
        } else {
          await tsxassApi.putV1AnalyticsFeedbackDevelopmentTasksId(
            this.taskId,
            this.surveyId,
            this.formData,
            this.userId,
          );
          this.$emit('submit');
        }
      } catch (err: any) {
        errorHandler(err);
        this.snackbarError = true;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.form.resetValidation();
      this.formData = { ...this.getInitialFormValue() };
      this.$emit('cancel');
    },
    async getIndicators() {
      try {
        this.loading = true;
        const { data: { questions } } = await tsxassApi.getV1AnalyticsFeedbackDevelopmentTasksQuestions(
          this.surveyId,
          this.userId,
        );
        this.questions = questions;
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async getTask() {
      try {
        this.loading = true;
        const { data } = await tsxassApi.getV1AnalyticsFeedbackDevelopmentTasksId(
          this.taskId,
          this.surveyId,
          this.userId,
        );
        this.formData = {
          ...this.getInitialFormValue(),
          ...data,
        };
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
