

import { EngagementAbility } from '@/helpers/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'RobotGptButton',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    failure: {
      type: Boolean,
      default: false,
    },
    response: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    canRunYandexGpt() {
      return this.can(EngagementAbility.CAN_RUN_YANDEX_GPT);
    },
    isRunAgainDisabled() {
      return this.errorText || this.loading || this.isResponseError;
    },
    runAgainButtonText() {
      return this.loading ? this.$t('yandexGPT.waitForАnalysis') : this.$t('yandexGPT.runAgain');
    },
    isResponseError() {
      return this.failure || (!this.loading && !this.response);
    },
    errorText() {
      if (this.response?.companyBalance < 0) {
        return this.$t('yandexGPT.noCompanyTokensAvailable');
      }
      if (this.response?.userOverheated) {
        return this.$t('yandexGPT.userOverheated');
      }
      return '';
    },

  },
};
