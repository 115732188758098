<template>
  <div class="gpt-sidebar">
    <RobotGptButton
      :loading="loading"
      :response="response"
      :failure="failure"
      @run-again-click="$emit('run-again-click')"
    />

    <TTTooltip
      left
      max-width="275px"
    >
      <template #activator="{ on, attrs }">
        <div
          class="gpt-instruction d-flex flex-column align-center"
          v-bind="attrs"
          v-on="on"
        >
          <TTBtn
            href="https://storage.yandexcloud.net/engagement-prod/manuals/Инструкция Yandex GPT.pdf"
            target="_blank"
            fab
            large
            color="tt-light-mono-0"
            class="has-shadow"
          >
            <VIcon
              size="18"
            >
              $bookLight
            </VIcon>
          </TTBtn>
          <div class="text-center button-caption tt-text-footnote tt-light-mono-64--text pt-2">
            {{ $t('yandexGPT.gptInstruction') }}
          </div>
        </div>
      </template>
      <span class="text-pre-line">
        {{ $t('yandexGPT.gptInstructionHint') }}
      </span>
    </TTTooltip>

    <div
      class="add-task d-flex flex-column align-center"
    >
      <TTBtn
        fab
        large
        color="tt-light-mono-0"
        class="has-shadow"
        @click="$emit('add-task')"
      >
        <VIcon
          size="18"
        >
          fal fa-file-plus
        </VIcon>
      </TTBtn>
      <div class="text-center button-caption tt-text-footnote tt-light-mono-64--text pt-2">
        {{ $t('yandexGPT.addTask') }}
      </div>
    </div>
  </div>
</template>

<script>
import RobotGptButton from '@/components/analytics/yandexGPT/RobotGptButton.vue';

export default {
  name: 'GptSidebar',
  components: {
    RobotGptButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    failure: {
      type: Boolean,
      default: false,
    },
    response: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.gpt-sidebar {
  position: fixed;
  right: 16px;
  top: 80px;
  z-index: 1;
}

.gpt-instruction {
  position: absolute;
  top: 100px;
  right: 0;
  width: 60px;
}

.add-task {
  position: absolute;
  top: 200px;
  right: 0;
  width: 60px;
}

.has-shadow {
  box-shadow: 0px 2px 16px rgba(11, 18, 24, 0.08) !important;
}
</style>
